import { createRouter, createWebHistory } from "vue-router";
import Main from "@/views/Main.vue";

const routes = [
    {
        path: "/",
        name: "main",
        component: Main,
    },
    {
        path: "/reservation",
        name: "reservation",
        component: () => import("@/views/Reservation.vue"),
    },
    {
        path: "/account",
        name: "account",
        component: () => import("@/views/Account.vue"),
    },
    {
        path: "/nikahi",
        name: "nikahi",
        component: () => import("@/views/Nikahi.vue"),
    },
    {
        path: "/loyalty-program",
        name: "loyalty-program",
        component: () => import("@/views/LoyaltyProgram.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
