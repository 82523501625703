<template>
    <main>
        <main-page-banner />
        <!-- <main-set-menu /> -->
        <main-set-menu-winter />
        <main-page-stories />
        <main-page-catalog />
        <main-page-reservation />
        <main-banquet-hall />
        <main-page-discounts />
        <main-page-photos />
    </main>
</template>

<script>
import MainPageBanner from "../components/MainPageBanner.vue";
import MainPageCatalog from "../components/MainPageCatalog.vue";
import MainPagePhotos from "../components/MainPagePhotos.vue";
import MainPageReservation from "../components/MainPageReservation.vue";
import MainPageStories from "../components/MainPageStories.vue";
import MainBanquetHall from "@/components/MainBanquetHall.vue";
import MainSetMenuWinter from "@/components/MainSetMenuWinter.vue";
import MainPageDiscounts from "@/components/MainPageDiscounts.vue";
//import MainSetMenu from "@/components/MainSetMenu.vue";
export default {
    components: {
        MainPageBanner,
        MainPageStories,
        MainPageCatalog,
        MainPageReservation,
        MainPagePhotos,
        MainBanquetHall,
        MainSetMenuWinter,
        MainPageDiscounts,
        //MainSetMenu,
    },
    name: "main-zaman",
};
</script>
